import { computed } from '@vue/composition-api'
import { Polish } from 'flatpickr/dist/l10n/pl'
import dayjs from 'dayjs'
import calendarEventFormValues from '@/views/_global/calendar-event-sidebar/calendarEventFormValues'

export default () => {
  const { calendarEvent } = calendarEventFormValues()

  return {
    dateFromFlatPickrSettings: computed(() => ({
      altInput: false,
      locale: Polish,
      enableTime: true,
      ariaDateFormat: 'Z',
      dateFormat: 'Y-m-d H:i',
      disableMobile: true,
      // maxDate: calendarEvent.value.endAt ? dayjs(calendarEvent.value.endAt).subtract(5, 'minute').format() : null,
    })),
    dateToFlatPickrSettings: computed(() => ({
      altInput: false,
      locale: Polish,
      enableTime: true,
      ariaDateFormat: 'Z',
      dateFormat: 'Y-m-d H:i',
      disableMobile: true,
      minDate: calendarEvent.value.startAt ? dayjs(calendarEvent.value.startAt)
        .add(5, 'minute')
        .format() : null,
    })),
    sequenceEndDateFlatPickrSettings: computed(() => ({
      altInput: false,
      locale: Polish,
      ariaDateFormat: 'Z',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      minDate: calendarEvent.value.startAt ? dayjs(calendarEvent.value.startAt)
        .add(1, 'day')
        .format() : null,
    })),
    specificDateFlatPickrSettings: computed(() => ({
      altInput: false,
      locale: Polish,
      ariaDateFormat: 'Z',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      maxDate: dayjs()
        .add(0, 'day')
        .format(),
    })),
  }
}
