<template>
  <div>

    <validation-observer
      ref="customerFormValidation"
      #default="{ handleSubmit }"
    >
      <b-modal
        id="add-customer-modal"
        ref="addCustomerModal"
        v-model="isShowingModal"
        :title="$t('customers.add-new')"
        :cancel-title="$t('shared.btn.cancel')"
        cancel-variant="outline-secondary"
        :ok-title="$t('shared.btn.add')"
        :ok-disabled="processing"
        @ok.prevent="phoneNumberValid ? handleSubmit(addCustomer) : true"
        @show="resetCustomer"
      >

        <form
          id="add-customer-form"
          autocomplete="off"
          @submit.stop.prevent
        >

          <b-row class="mb-50">
            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('customers.form.base-information.first-name')"
                rules="required|min:2"
              >
                <b-form-group
                  :label="$t('customers.form.base-information.first-name')"
                  label-for="customer-first-name"
                  :state="getValidationState(validationContext)"
                >
                  <input
                    id="customer-first-name"
                    v-model="customer.firstName"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    minlength="2"
                    maxlength="50"
                    spellcheck="false"
                  >
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('customers.form.base-information.last-name')"
                rules="required|min:2"
              >
                <b-form-group
                  :label="$t('customers.form.base-information.last-name')"
                  label-for="customer-first-name"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="customer-last-name"
                    v-model="customer.lastName"
                    type="text"
                    minlength="2"
                    maxlength="50"
                    autocomplete="off"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('customers.form.contact-information.phone')"
                :rules="!customer.email ? 'required' : ''"
              >
                <b-form-group
                  :label="$t('customers.form.contact-information.phone')"
                  label-for="customer-contact-phone"
                  :state="getValidationState(validationContext)"
                >
                  <vue-tel-input
                    v-model="customer.phone"
                    mode="international"
                    :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                    :input-options="{ placeholder: '', showDialCode: false }"
                    :valid-characters-only="true"
                    @validate="handlePhoneNumberValidation"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="validationContext.errors.length === 0 && !phoneNumberValid"
                    class="text-danger"
                  >{{ $t('validations.messages.phone-number-invalid') }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('customers.form.contact-information.email')"
                :rules="!customer.phone ? 'required|email' : 'email'"
              >
                <b-form-group
                  :label="$t('customers.form.contact-information.email')"
                  label-for="customer-contact-email"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="customer-contact-email"
                    v-model="customer.email"
                    type="text"
                    autocomplete="off"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>

        </form>
      </b-modal>
    </validation-observer>

  </div>
</template>

<script>
import {
  BModal, BFormInvalidFeedback, BFormInput, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { cloneNested, parseRequestError } from '@/helpers/helpers'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  name: 'AddCustomerModal',
  components: {
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormGroup,

    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  setup(props, { emit }) {
    const customer = ref(cloneNested(store.state.customer.customerModel))
    const customerFormValidation = ref(null)
    const addCustomerModal = ref(null)
    const isShowingModal = ref(null)
    const processing = ref(null)
    const phoneNumberValid = ref(true)

    const handlePhoneNumberValidation = phoneObject => {
      customerFormValidation.value.reset()
      phoneNumberValid.value = phoneObject.valid === undefined || phoneObject.valid || phoneObject.formatted === ''
    }

    const resetCustomer = () => {
      customer.value = cloneNested(store.state.customer.customerModel)
    }

    const addCustomer = () => {
      processing.value = true
      store.dispatch(
        'customer/addCustomer',
        { data: customer.value },
      )
        .then(response => {
          emit('customer-added', response.data)
          resetCustomer()
          isShowingModal.value = false
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    const {
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetCustomer)

    return {
      isShowingModal,
      customer,
      addCustomerModal,
      processing,

      phoneNumberValid,
      handlePhoneNumberValidation,

      addCustomer,
      resetCustomer,

      // validations
      customerFormValidation,
      getValidationState,
      resetForm,
      clearForm,
    }
  },
}
</script>

<style scoped>

</style>
