<template>
  <div>
    <add-customer-modal @customer-added="handleNewCustomer" />
    <cancel-event-modal
      :calendar-event="calendarEvent"
      :customer="customerToRemove"
      @hiding-modal="customerToRemove = null"
      @event-cancelled="formVisible = false; $emit('change')"
      @participant-removed="participantRemovedEventHandler"
    />
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="formVisible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="init"
      @hidden="$store.dispatch('calendarEventEditing/cleanUpEditing')"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ sidebarHeaderTitle }}
          </h5>
          <div>
            <b-link
              v-if="calendarEvent.type === CalendarEventTypeDictionary.Appointment"
              :to="{ name: 'organization-meeting-view', params: { id: calendarEvent.id } }"
              class="ml-1"
              @click="formVisible = false"
            >
              <feather-icon
                v-if="calendarEvent.id"
                icon="SearchIcon"
              />
            </b-link>
            <feather-icon
              v-if="calendarEvent.id"
              v-b-modal="'cancel-event-modal'"
              icon="TrashIcon"
              class="ml-1 cursor-pointer"
              @click="customerToRemove = null"
            />
            <feather-icon
              icon="XIcon"
              class="ml-1 cursor-pointer"
              size="16"
              @click="formVisible = false"
            />
          </div>
        </div>

        <rc-overlay
          :show="processing"
          no-fade
          variant="white"
          class="h-100"
        >
          <!-- Body -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <b-alert
              :show="formVisible && !processing && services.length === 0"
              variant="info"
              class="m-2"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">
                  {{ $t('calendar.no-services-alert') }}
                  <b-link :to="{ name: 'organization-services' }">{{ $t('calendar.no-services-alert-here') }}</b-link>.
                </span>
              </div>
            </b-alert>

            <!-- Form -->
            <b-form
              v-if="formVisible && services.length > 0"
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >

              <app-collapse
                accordion
                type="margin"
              >

                <app-collapse-item
                  :title="$t('calendar.event.form.header.base-info')"
                  :is-visible="true"
                  class="app-tour-event-section-basic"
                >

                  <b-form-group
                    v-if="!processing && !calendarEvent.id"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio-group
                      id="btn-options-initiator"
                      v-model="calendarEvent.type"
                      :options="eventTypeOptions"
                      button-variant="outline-primary"
                      :aria-describedby="ariaDescribedby"
                      class="d-flex"
                      buttons
                      @change="eventTypeChangeHandler"
                    />
                  </b-form-group>

                  <!-- Service -->
                  <validation-provider
                    v-if="calendarEvent.type === CalendarEventTypeDictionary.Appointment"
                    #default="validationContext"
                    :name="$t('calendar.event.form.label.service')"
                    rules="required"
                  >

                    <b-form-group
                      :label="$t('calendar.event.form.label.service')"
                      label-for="service"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="calendarEvent.service"
                        :options="services"
                        label="name"
                        :reduce="service => service.id"
                        input-id="service"
                        :clearable="false"
                        :disabled="!!calendarEvent.id"
                      >
                        <!--                        @option:selected="serviceChangeHandler"-->

                        <template #option="option">
                          <div class="line-height-condensed">
                            <span class="">{{ option.name }}</span><br>
                            <span class="font-small-2">{{ option.shortDescription }}</span>
                          </div>
                        </template>

                        <template #selected-option="option">
                          <div v-if="option.id">
                            <strong class="line-height-condensed">{{ option.name }}</strong>
                          </div>
                          <div v-else>
                            <strong class="line-height-condensed">{{ $t('services.form.danger-zone.archived') }}</strong>
                          </div>
                        </template>

                        <template #no-options>
                          {{ $t('shared.no-data') }}
                        </template>

                      </v-select>

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>

                      <div
                        v-if="calendarEvent.service"
                        v-acl="role.advancedEducator"
                      >
                        <p
                          v-if="isEventDifferent"
                          class="text-right"
                        >
                          <b-link @click="copyServiceSettingsToEvent">
                            <small>
                              {{ $t('calendar.event.form.button.restore-service-defaults') }}
                            </small>
                          </b-link>
                        </p>
                        <p
                          v-else
                          class="text-right"
                        >
                          <b-link @click="overrideServiceDefaults = true">
                            <small>
                              {{ $t('calendar.event.form.button.override-service-defaults') }}
                            </small>
                          </b-link>
                        </p>
                      </div>
                    </b-form-group>
                  </validation-provider>

                  <b-row>
                    <b-col sm="6">

                      <!-- Start Date -->
                      <validation-provider
                        #default="validationContext"
                        :name="$t('calendar.event.form.label.start-at')"
                        rules="required"
                      >

                        <b-form-group
                          :label="$t('calendar.event.form.label.start-at')"
                          label-for="start-date"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            id="start-date"
                            v-model="calendarEvent.startAt"
                            class="form-control"
                            :config="dateFromFlatPickrSettings"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col sm="6">
                      <!-- End Date -->
                      <validation-provider
                        #default="validationContext"
                        :name="$t('calendar.event.form.label.end-at')"
                        rules="required"
                      >

                        <b-form-group
                          :label="$t('calendar.event.form.label.end-at')"
                          label-for="end-date"
                          :state="getValidationState(validationContext)"
                        >
                          <div v-if="isEventDifferent || calendarEvent.type === CalendarEventTypeDictionary.TimeLock">
                            <flat-pickr
                              id="end-date"
                              v-model="calendarEvent.endAt"
                              class="form-control"
                              :config="dateToFlatPickrSettings"
                            />
                          </div>
                          <div v-else>
                            <b-form-input
                              :value="calendarEvent.endAt ? dayjs(calendarEvent.endAt).format('YYYY-MM-DD HH:mm') : null"
                              :disabled="true"
                            />
                          </div>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <b-form-checkbox
                    v-if="calendarEvent.type === CalendarEventTypeDictionary.TimeLock"
                    v-model="calendarEvent.fullDay"
                    name="full-day-checkbox"
                    inline
                    class="mb-1"
                    @change="fullDayChangeHandler"
                  >
                    {{ $t('calendar.event.form.label.full-day') }}
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-if="calendarEvent.type === CalendarEventTypeDictionary.TimeLock && collisions.length"
                    v-model="cancelConflictingEvents"
                    name="cancel-conflicting-events-checkbox"
                    inline
                    class="mb-1"
                  >
                    {{ $t('calendar.event.form.label.cancel-conflicting-events') }}
                  </b-form-checkbox>

                  <!-- Participants -->
                  <b-form-group
                    v-if="calendarEvent.type === CalendarEventTypeDictionary.Appointment"
                    :label="$t('calendar.event.form.label.participants') + (calendarEvent.calendarEventCustomers.length ? (' (' + calendarEvent.calendarEventCustomers.length + ')') : '')"
                    label-for="add-participants"
                  >
                    <v-select
                      v-model="selectedCustomer"
                      close-on-select
                      clear-search-on-select
                      :options="customers"
                      :filter-by="searchCustomer"
                      :reduce="option => option.customer.id"
                      :placeholder="$t('calendar.event.form.label.participants-placeholder')"
                      input-id="add-participants"
                      @option:selected="addParticipant"
                    >

                      <template #option="{ customer }">
                        <span v-if="customer">
                          <b-avatar
                            v-if="customer.firstName && customer.lastName"
                            size="sm"
                          >
                            {{ customer.firstName.charAt(0) + customer.lastName.charAt(0) }}
                          </b-avatar>
                          <span class="ml-50 align-middle"> {{ customer.lastName }} {{ customer.firstName }}</span>
                        </span>
                      </template>

                      <template #selected-option="{ customer }">
                        <span v-if="customer">
                          <b-avatar
                            v-if="customer.firstName && customer.lastName"
                            size="sm"
                          >
                            {{ customer.firstName.charAt(0) + customer.lastName.charAt(0) }}
                          </b-avatar>
                          <span class="ml-50 align-middle"> {{ customer.lastName }} {{ customer.firstName }}</span>
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('shared.no-data') }}
                      </template>

                    </v-select>

                    <p class="text-right">
                      <b-link v-b-modal.add-customer-modal>
                        <small>
                          <feather-icon icon="PlusIcon" /> {{ $t('calendar.add-client') }}
                        </small>
                      </b-link>
                    </p>
                  </b-form-group>

                  <!-- educator calendar -->
                  <validation-provider
                    v-if="me.roles.includes(role.advancedEducator) && educators.length > 1"
                    #default="validationContext"
                    :name="calendarEvent.type === CalendarEventTypeDictionary.Appointment ? $t('calendar.event.form.label.educator') : $t('calendar.event.form.label.event-concerns-calendar')"
                    rules="required"
                  >

                    <b-form-group
                      :label="calendarEvent.type === CalendarEventTypeDictionary.Appointment ? $t('calendar.event.form.label.educator') : $t('calendar.event.form.label.event-concerns-calendar')"
                      label-for="educator"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="calendarEvent.calendar"
                        :options="educators"
                        label="educator"
                        :reduce="educator => educator.calendar.id"
                        input-id="educator"
                        :clearable="false"
                      >

                        <template #option="option">
                          <div class="line-height-condensed">
                            <span class="">{{ option.firstName }} {{ option.lastName }}</span>
                          </div>
                        </template>

                        <template #selected-option="option">
                          <div v-if="option.id">
                            <span class="">{{ option.firstName }} {{ option.lastName }}</span>
                          </div>
                          <div v-else>
                            <strong class="line-height-condensed">{{ $t('services.form.danger-zone.archived') }}</strong>
                          </div>
                        </template>

                        <template #no-options>
                          {{ $t('shared.loading') }}
                        </template>

                      </v-select>

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Location -->
                  <b-form-group
                    v-if="calendarEvent.type === CalendarEventTypeDictionary.Appointment"
                    :label="$t('calendar.event.form.label.location')"
                    label-for="event-location"
                  >
                    <v-select
                      id="event-location"
                      v-model="calendarEvent.location"
                      :options="serviceLocations"
                      :clearable="false"
                      :searchable="false"
                      :reduce="(option) => option.id"
                      :disabled="!isEventDifferent && serviceDetails ? serviceDetails.serviceLocation.length === 1 : false"
                    />
                  </b-form-group>

                  <!-- Location details -->
                  <b-form-group
                    v-show="calendarEvent.location"
                    :label="$t('calendar.event.form.label.location-details')"
                    label-for="event-location-details"
                  >
                    <b-form-input
                      id="event-location-details"
                      v-model="calendarEvent.locationDetails"
                      trim
                      :placeholder="calendarEvent.location ? $t('calendar.event.form.label.location-details-placeholder.' + calendarEvent.location) : ''"
                    />
                  </b-form-group>

                </app-collapse-item>

                <app-collapse-item
                  v-if="calendarEvent.type === CalendarEventTypeDictionary.Appointment"
                  :title="$tc('calendar.event.form.header.participant-plural', Object.keys(calendarEvent.calendarEventCustomers).length, {n: Object.keys(calendarEvent.calendarEventCustomers).length})"
                  class="app-tour-event-section-participants"
                >
                  <customers-list @remove-participant="removeParticipantEventHandler" />
                </app-collapse-item>

                <app-collapse-item
                  v-if="calendarEvent.type === CalendarEventTypeDictionary.Appointment && (!calendarEvent.id || calendarEventIsRecurring)"
                  class="app-tour-event-section-recurring"
                  :title="$t('calendar.event.form.header.recurring')"
                >

                  <!-- Sequence -->
                  <div>
                    <!-- Sequence End Date -->
                    <validation-provider
                      #default="validationContext"
                      :name="$t('calendar.event.form.label.recurrence-end-date')"
                      :rules="{ 'required': calendarEventIsRecurring }"
                    >

                      <b-form-group
                        :label="$t('calendar.event.form.label.recurrence-end-date')"
                        label-for="recurrence-end-date"
                        :state="getValidationState(validationContext)"
                      >
                        <div v-if="!(calendarEvent.calendarEventSequence && calendarEvent.calendarEventSequence.id)">
                          <flat-pickr
                            id="recurrence-end-date"
                            v-model="calendarEvent.calendarEventSequence.sequenceEndDate"
                            class="form-control"
                            :config="sequenceEndDateFlatPickrSettings"
                          />
                        </div>
                        <div v-else>
                          <b-form-input
                            :value="dayjs(calendarEvent.calendarEventSequence.sequenceEndDate).format('YYYY-MM-DD')"
                            :disabled="true"
                          />
                        </div>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-row>
                      <b-col>

                        <!-- Interval value -->
                        <validation-provider
                          #default="validationContext"
                          :name="$t('calendar.event.form.label.recurrence-interval-value')"
                          :rules="{ 'required': calendarEventIsRecurring, 'integer': calendarEventIsRecurring }"
                        >

                          <b-form-group
                            :label="$t('calendar.event.form.label.recurrence-interval-value')"
                            label-for="sequence-interval-value"
                            :state="getValidationState(validationContext)"
                          >
                            <b-form-input
                              id="sequence-interval-value"
                              v-model="calendarEvent.calendarEventSequence.intervalValue"
                              :disabled="!!(calendarEvent.calendarEventSequence && calendarEvent.calendarEventSequence.id)"
                              type="number"
                              trim
                              @blur="validateCollisions"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>

                        </validation-provider>

                      </b-col>

                      <b-col v-if="calendarEvent.calendarEventSequence.intervalValue > 0">

                        <!-- Interval type -->
                        <validation-provider
                          #default="validationContext"
                          :name="$t('calendar.event.form.label.recurrence-interval-type-label')"
                          :rules="{ 'required': calendarEventIsRecurring }"
                        >
                          <b-form-group
                            :label="$t('calendar.event.form.label.recurrence-interval-type-label')"
                            label-for="sequence-interval-type"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="calendarEvent.calendarEventSequence.intervalType"
                              :options="intervalTypes"
                              :disabled="!!(calendarEvent.calendarEventSequence && calendarEvent.calendarEventSequence.id)"
                              input-id="sequence-interval-type"
                              :reduce="(option) => option.id"
                              @input="validateCollisions"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                  </div>

                </app-collapse-item>

                <app-collapse-item
                  v-if="isEventDifferent && calendarEvent.service"
                  v-acl="role.advancedEducator"
                  :title="$t('calendar.event.form.header.settings')"
                >

                  <!-- Price -->
                  <b-form-group
                    :label="$t('calendar.event.form.label.price')"
                    label-for="price-gross"
                  >
                    <b-input-group
                      append="zł"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="price-gross"
                        v-model="calendarEvent.priceGross"
                        type="number"
                        step="0.01"
                        value="0.00"
                        class="text-right"
                        placeholder="50.00"
                        autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>

                  <!-- Additional time before -->
                  <b-form-group
                    :label="$t('calendar.event.form.label.add-time-before')"
                    label-for="additionalTimeBefore"
                  >
                    <duration-selector
                      id="additionalTimeBefore"
                      v-model="calendarEvent.additionalTimeBefore"
                      :max-hour="6"
                    />
                  </b-form-group>

                  <!-- Additional time after -->
                  <b-form-group
                    :label="$t('calendar.event.form.label.add-time-after')"
                    label-for="additionalTimeAfter"
                  >
                    <duration-selector
                      id="additionalTimeAfter"
                      v-model="calendarEvent.additionalTimeAfter"
                      :max-hour="6"
                    />
                  </b-form-group>

                  <!-- Participant limit -->
                  <b-form-group
                    :label="$t('calendar.event.form.label.participant-limit')"
                    label-for="participants-limit"
                  >
                    <b-form-input
                      id="participants-limit"
                      v-model="calendarEvent.participantLimit"
                      trim
                    />
                  </b-form-group>

                </app-collapse-item>

              </app-collapse>

              <!-- alerts -->
              <b-row>
                <b-col>
                  <b-alert
                    :show="!!collisions.length"
                    variant="warning"
                    class="mt-2"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="AlertTriangleIcon"
                      />
                      <span class="ml-25">
                        <strong>{{
                          $tc('calendar.collision-detected', collisions.length, { n: collisions.length })
                        }}</strong>:
                        <ul class="mt-1">
                          <li
                            v-for="(collision) in collisions"
                            :key="collision.id"
                          >
                            <feather-icon
                              v-if="collision.type === CalendarEventTypeDictionary.Appointment"
                              icon="CalendarIcon"
                            />
                            <feather-icon
                              v-if="collision.type === CalendarEventTypeDictionary.TimeLock"
                              icon="CoffeeIcon"
                            />
                            {{
                              dayjs(collision.startAt)
                                .format('DD.MM.YYYY HH:mm')
                            }} - {{
                              dayjs(collision.endAt)
                                .format('DD.MM.YYYY HH:mm')
                            }}
                            <div
                              v-if="collision.additionalTimeBefore || collision.additionalTimeAfter"
                              class="font-small-2"
                            >
                              {{
                                dayjs(collision.startAt)
                                  .subtract(collision.additionalTimeBefore, 'minute')
                                  .format('DD.MM.YYYY HH:mm')
                              }} - {{
                                dayjs(collision.endAt)
                                  .add(collision.additionalTimeAfter, 'minute')
                                  .format('DD.MM.YYYY HH:mm')
                              }}
                              <rc-help
                                :title="$t('shared.help')"
                                :description="$t('calendar.tips.collisions-additional-time')"
                              />
                            </div>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </b-alert>

                  <b-alert
                    :show="isOutOfServiceHours"
                    variant="info"
                    class="mt-2"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="InfoIcon"
                      />
                      <span class="ml-25">
                        {{ $t('calendar.is-out-of-service-hours') }}:
                        {{ serviceDetails.serviceTimeFrom }}-{{ serviceDetails.serviceTimeTo }}
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>

              <!-- Form Actions -->
              <b-row class="mt-2 mb-5">
                <b-col class="mb-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-block app-tour-event-button-add"
                    type="submit"
                    @click="updateFutureEvents = false"
                  >
                    {{ calendarEvent.id ? $t('calendar.event.form.button.update') : $t('calendar.event.form.button.add') }}
                  </b-button>
                </b-col>
                <b-col
                  v-if="calendarEvent.id && calendarEventIsRecurring"
                  class="mb-1"
                >
                  <b-button
                    v-if="calendarEvent.id && calendarEventIsRecurring"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-block"
                    type="submit"
                    @click="updateFutureEvents = true"
                  >
                    {{ $t('calendar.event.form.button.update-series') }}
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </rc-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email, minValue, required, url,
} from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import DurationSelector from '@/layouts/components/rc/DurationSelector'
import AddCustomerModal from '@/views/_global/AddCustomerModal'
import calendarEventSidebarSetup from '@/views/_global/calendar-event-sidebar/calendarEventSidebarSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import CustomersList from '@/views/_global/calendar-event-sidebar/CustomersList'
import RcHelp from '@/layouts/components/rc/RcHelp'
import CancelEventModal from '@/views/_global/CancelEventModal.vue'

export default {
  components: {
    CancelEventModal,
    RcHelp,
    CustomersList,
    AddCustomerModal,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    DurationSelector,

    RcOverlay,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      email,
      url,
      minValue,
    }
  },
  computed: {
    intervalTypes() {
      return [
        {
          id: 'day',
          label: this.$tc('calendar.event.form.label.recurrence-interval-type.day', Number(this.calendarEvent.calendarEventSequence.intervalValue)),
        },
        {
          id: 'week',
          label: this.$tc('calendar.event.form.label.recurrence-interval-type.week', Number(this.calendarEvent.calendarEventSequence.intervalValue)),
        },
        {
          id: 'month',
          label: this.$tc('calendar.event.form.label.recurrence-interval-type.month', Number(this.calendarEvent.calendarEventSequence.intervalValue)),
        },
      ]
    },
  },
  setup: calendarEventSidebarSetup,
}
</script>
