import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'

export default (props, { emit }) => {
  const menuProp = ref(null)
  const calendarEventToCancel = ref(null)

  const clickEventHandler = event => {
    store.dispatch('calendarEventEditing/activateEditing', event.id)
  }

  const handleEventCancelled = () => {
    calendarEventToCancel.value = null
    emit('refetch')
  }

  const cancelCalendarEvent = calendarEvent => {
    emit('event-cancellation-request', calendarEvent)
  }

  return {
    calendarEventToCancel,
    cancelCalendarEvent,
    clickEventHandler,
    handleEventCancelled,
    menuProp,
    dayjs,
  }
}
