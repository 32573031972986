<template>
  <div class="dashboard-statistics">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card
          class="earnings-card"
        >

          <rc-overlay
            :show="processing"
            no-fade
            variant="white"
            class="h-100"
          >
            <b-row>
              <b-col
                cols="6"
              >
                <b-card-title class="mb-2">
                  {{ $t('dashboard.statistics.welcome') }},
                  {{ me.firstName }} 🤝
                </b-card-title>
                <div
                  v-if="todayMeetingsLeft > 0"
                  class="font-small-3"
                >
                  {{ $t('dashboard.statistics.today.meetings-left') }}
                  <h5 class="mb-1 font-medium-3">
                    {{ $tc('dashboard.statistics.today.meetings-left-plural', todayMeetingsLeft, { n: todayMeetingsLeft }) }}
                  </h5>
                </div>
                <div
                  v-else
                  class="font-small-3"
                >
                  {{ $t('dashboard.statistics.today.no-meetings-left') }}
                </div>

                <div>
                  <b-button
                    v-b-tooltip.hover
                    variant="flat-primary"
                    class="btn-icon rounded-circle mt-50"
                    size="sm"
                    :title="showStatistics?$t('dashboard.statistics.hide') : $t('dashboard.statistics.show')"
                    @click="updateShowStatistics(!showStatistics)"
                  >
                    <feather-icon :icon="showStatistics ? 'EyeOffIcon' : 'EyeIcon'" />
                  </b-button>
                </div>
              </b-col>
              <b-col
                v-acl="[role.admin]"
                cols="6"
              >
                <statistic-card-vertical
                  :hide="!showStatistics"
                  color="success"
                  icon="DollarSignIcon"
                  :statistic="`${Math.round(calendarEventCustomersTotalStatistics.todayIncomePaid || 0)} zł`"
                  :statistic-title="$t('dashboard.statistics.today.income-paid')"
                  class="mb-n2 mt-n2"
                />
              </b-col>
            </b-row>
          </rc-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-acl="[role.admin]">
      <b-col
        cols="12"
        class="mb-1"
      >
        <div class="d-flex justify-content-between">
          <h4>
            {{ $t('dashboard.statistics.finance.title') }}
          </h4>
        </div>
      </b-col>
      <b-col cols="6">
        <b-link
          :to="to.payments.toPay"
          class="settlement-block-link"
        >
          <statistic-card-vertical
            :hide="!showStatistics"
            :loading="processing"
            color="warning"
            icon="DollarSignIcon"
            :statistic="`${Math.round(calendarEventCustomersTotalStatistics.incomeToPay)} zł`"
            :statistic-title="$t('dashboard.statistics.finance.waiting-for-payment')"
          />
        </b-link>
      </b-col>
      <b-col cols="6">
        <b-link
          :to="to.payments.overduePayment"
          class="settlement-block-link"
        >
          <statistic-card-vertical
            :hide="!showStatistics"
            :loading="processing"
            color="danger"
            icon="DollarSignIcon"
            :statistic="`${Math.round(calendarEventCustomersTotalStatistics.incomeOverdue)} zł`"
            :statistic-title="$t('dashboard.statistics.finance.overdue-payment')"
          />
        </b-link>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <div class="d-flex justify-content-between">
          <h4>
            {{ $t('dashboard.statistics.month.title') }}
          </h4>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="changeMonthButtonClickHandler"
          >
            {{ currentMonthFormatted }}

            <flat-pickr
              ref="monthSelector"
              v-model="selectedMonth"
              class="hidden-flatpickr d-flex"
              :config="configDateTimePicker"
              :name="$t('report.reports.filters.month')"
              @on-change="monthChangeEventHandler"
            />
          </b-button>
        </div>
      </b-col>

      <b-col cols="6">
        <statistic-card-vertical
          :hide="!showStatistics"
          :loading="fetchingMonthlyStatistics"
          color="info"
          icon="CalendarIcon"
          :statistic="Number(calendarEventsStatistics.pastEvents)"
          :statistic-title="$t('dashboard.statistics.month.left-meetings-number')"
        />
      </b-col>
      <b-col cols="6">
        <statistic-card-vertical
          :hide="!showStatistics"
          :loading="fetchingMonthlyStatistics"
          color="success"
          icon="CalendarIcon"
          :statistic="monthMeetingsTotal"
          :statistic-title="$t('dashboard.statistics.month.meetings-number')"
        />
      </b-col>
      <b-col
        v-acl="[role.admin]"
        cols="6"
      >
        <b-link
          :to="to.monthlyPayments.paid"
          class="settlement-block-link"
        >
          <statistic-card-vertical
            :hide="!showStatistics"
            :loading="fetchingMonthlyStatistics"
            color="success"
            icon="DollarSignIcon"
            :statistic="`${Math.round(calendarEventCustomersMonthStatistics.incomePaid)} zł`"
            :statistic-title="$t('dashboard.statistics.month.paid')"
          />
        </b-link>
      </b-col>
      <b-col
        v-acl="[role.admin]"
        cols="6"
      >
        <b-link
          :to="to.monthlyPayments.toPay"
          class="settlement-block-link"
        >
          <statistic-card-vertical
            :hide="!showStatistics"
            :loading="fetchingMonthlyStatistics"
            color="warning"
            icon="DollarSignIcon"
            :statistic="`${Math.round(calendarEventCustomersMonthStatistics.incomeToPay)} zł`"
            :statistic-title="$t('dashboard.statistics.month.waiting-for-payment')"
          />
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import _ from 'lodash'
import flatPickr from 'vue-flatpickr-component'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import { parseRequestError } from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import StatisticCardVertical from '@/layouts/components/statistics-cards/StatisticCardVertical'
import i18n from '@/libs/i18n'
import role from '@/auth/role'
import SettlementStatusDictionary from '@/dictionary/SettlementStatusDictionary'
import SettlementTypeDictionary from '@/dictionary/SettlementTypeDictionary'

const models = {
  calendarEventsStatistics: {
    pastEvents: '0',
    plannedEvents: '0',
    todayPastEvents: '0',
    todayTotalEvents: '0',
    totalEvents: '0',
  },
  calendarEventCustomersTotalStatistics: {
    incomePaid: '0',
    incomeToPay: '0',
    incomeOverdue: '0',
    todayIncomes: '0',
    totalEvents: '0',
  },
  calendarEventCustomersMonthStatistics: {
    incomePaid: '0',
    incomeToPay: '0',
    incomeOverdue: '0',
    todayIncomes: '0',
    totalEvents: '0',
  },
}
export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BCardTitle,

    RcOverlay,
    StatisticCardVertical,
    flatPickr,
  },
  data() {
    return {
      calendarEventsStatistics: {
        pastEvents: '0',
        plannedEvents: '0',
        todayPastEvents: '0',
        todayTotalEvents: '0',
        totalEvents: '0',
      },
      calendarEventCustomersTotalStatistics: {
        incomePaid: '0',
        incomeToPay: '0',
        incomeOverdue: '0',
        todayIncomePaid: '0',
        totalEvents: '0',
      },
      calendarEventCustomersMonthStatistics: {
        incomePaid: '0',
        incomeToPay: '0',
        incomeOverdue: '0',
        todayIncomes: '0',
        totalEvents: '0',
      },
      processing: true,
      showStatistics: true,

      monthSelector: null,
      selectedMonth: dayjs().format('YYYY-MM'),
      fetchingMonthlyStatistics: true,
      configDateTimePicker: {
        locale: PolishLocale,
        disableMobile: true,
        plugins: [
          // eslint-disable-next-line new-cap
          new monthSelectPlugin({
            dateFormat: 'Y-m', // defaults to "F Y"
            altFormat: 'Y-m', // defaults to "F Y"
          }),
        ],
      },
    }
  },
  computed: {
    role() {
      return role
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
    organizationCreatedAt() {
      return dayjs(this.me.organization.createdAt)
    },
    currentMonthFormatted() {
      const dayObject = dayjs(`${this.selectedMonth}-01`)
      const monthString = i18n.t(`shared.months.${dayObject.month()}`)
      return `${monthString} ${dayObject.year()}r`
    },
    todayMeetingsLeft() {
      return Number(this.calendarEventsStatistics.todayTotalEvents) - Number(this.calendarEventsStatistics.todayPastEvents)
    },
    monthMeetingsTotal() {
      return Number(this.calendarEventsStatistics.pastEvents) + Number(this.calendarEventsStatistics.plannedEvents)
    },
    to() {
      return {
        payments: {
          toPay: {
            name: 'settlements-list',
            query: {
              'filter.status.0': SettlementStatusDictionary.ToPay,
              'filter.status.1': SettlementStatusDictionary.OverduePayment,
              'filter.from': this.organizationCreatedAt.startOf('day').format(),
              'filter.to': dayjs().endOf('day').format(),
            },
          },
          overduePayment: {
            name: 'settlements-list',
            query: {
              'filter.status.0': SettlementStatusDictionary.OverduePayment,
              'filter.from': this.organizationCreatedAt.startOf('day').format(),
              'filter.to': dayjs().endOf('day').format(),
            },
          },
        },
        monthlyPayments: {
          paid: {
            name: 'settlements-list',
            query: {
              'filter.status.0': SettlementStatusDictionary.Paid,
              'filter.type.0': SettlementTypeDictionary.INCOMING_PAYMENT,
              'filter.from': dayjs(this.selectedMonth).startOf('month').format(),
              'filter.to': dayjs(this.selectedMonth).endOf('month').format(),
            },
          },
          toPay: {
            name: 'settlements-list',
            query: {
              'filter.status.0': SettlementStatusDictionary.ToPay,
              'filter.status.1': SettlementStatusDictionary.OverduePayment,
              'filter.type.0': SettlementTypeDictionary.ACCOUNTING_FOR_CLASSES,
              'filter.type.1': SettlementTypeDictionary.ACCOUNTING_FOR_LATE_CANCELLATION,
              'filter.from': dayjs(this.selectedMonth).startOf('month').format(),
              'filter.to': dayjs(this.selectedMonth).endOf('month').format(),
            },
          },
        },
      }
    },
  },
  beforeMount() {
    this.showStatistics = localStorage.getItem('show-statistics') !== 'false'

    this.fetchStatistics()
    this.fetchMonthlyStatistics()
  },
  methods: {
    fetchStatistics() {
      const to = dayjs(`${this.selectedMonth}-01`).endOf('month').format()
      this.calendarEventCustomersTotalStatistics = _.cloneDeep(models.calendarEventCustomersTotalStatistics)

      this.$store.dispatch(
        'calendar/fetchCalendarEventCustomersStatistics',
        {
          to,
        },
      )
        .then(res => {
          this.calendarEventCustomersTotalStatistics = _.merge(this.calendarEventCustomersTotalStatistics, res.data)
          this.processing = false
        })
        .catch(err => parseRequestError(err))
    },
    fetchMonthlyStatistics() {
      this.fetchingMonthlyStatistics = true
      const from = dayjs(`${this.selectedMonth}-01`).startOf('month').format()
      const to = dayjs(`${this.selectedMonth}-01`).endOf('month').format()

      this.calendarEventsStatistics = _.cloneDeep(models.calendarEventsStatistics)
      this.calendarEventCustomersMonthStatistics = _.cloneDeep(models.calendarEventCustomersMonthStatistics)

      Promise.all([
        this.$store.dispatch(
          'calendar/fetchCalendarEventsStatistics',
          {
            from,
            to,
          },
        ),
        this.$store.dispatch(
          'calendar/fetchCalendarEventCustomersStatistics',
          {
            from,
            to,
          },
        ),
      ])
        .then(res => {
          this.calendarEventsStatistics = _.merge(this.calendarEventsStatistics, res[0].data)
          this.calendarEventCustomersMonthStatistics = _.merge(this.calendarEventCustomersMonthStatistics, res[1].data)
          this.fetchingMonthlyStatistics = false
        })
        .catch(err => parseRequestError(err))
    },
    updateShowStatistics(val) {
      this.showStatistics = val
      localStorage.setItem('show-statistics', val)
    },
    changeMonthButtonClickHandler() {
      // eslint-disable-next-line
      this.$refs.monthSelector.$el._flatpickr.open()
    },
    monthChangeEventHandler() {
      this.$nextTick(this.fetchMonthlyStatistics)
    },
  },
}
</script>

<style lang="scss">
.dashboard-statistics {
  .settlement-block-link {
    display: block;
    transition-property: transform;
    transition-duration: .2s;
    transition-timing-function: ease;

    &:hover {
      transform: scale(1.1);
      > div {
        transition-duration: .2s;
        transition-timing-function: ease-out;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3);
      }
    };
  }
}
</style>
