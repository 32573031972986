<template>
  <b-list-group
    class="mb-1"
  >
    <b-list-group-item
      v-for="(calendarEventCustomer, index) in sort(calendarEvent.calendarEventCustomerObjects)"
      :key="index"
      class="d-flex justify-content-between align-items-center"
    >
      <b-link
        :to="{name: 'organization-customers-view', params: {customerId: calendarEventCustomer.customer.id}}"
        @click="formVisible = false"
      >
        {{ calendarEventCustomer.customer.lastName }} {{ calendarEventCustomer.customer.firstName }}
      </b-link>

      <span>
        <b-badge
          v-if="!calendarEventCustomer.id"
          variant="primary"
          class="mr-1"
          pill
        >
          {{ $t('shared.new') }}
        </b-badge>
        <b-button
          variant="flat-danger"
          size="sm"
          @click="$emit('remove-participant', calendarEventCustomer.customer)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </span>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
import calendarEventFormValues from '@/views/_global/calendar-event-sidebar/calendarEventFormValues'
import { sort } from '@/helpers/helpers'

export default {
  setup() {
    const {
      calendarEvent,
      formVisible,
    } = calendarEventFormValues()

    return {
      calendarEvent,
      formVisible,
      sort,
    }
  },
}

</script>
