<template>
  <div>
    <cancel-event-modal
      :id="`cancel-event-modal-${event.id}`"
      :calendar-event="event"
      @event-cancelled="handleEventCancelled"
    />

    <b-card
      v-ripple.400="'rgba(255, 255, 255, 0.05)'"
      text-variant="white"
      class="mb-1 cursor-pointer"
      :class="{'card-muted': dayjs(event.endAt).diff() < 0}"
      :style="`background-color: ${event.service.color};`"
      no-body
      @click.prevent="menuProp.open"
    >
      <b-card-body>
        <h5>
          <span
            v-if="event.calendarEventSequence"
            class="float-right text-white text-nowrap d-none d-sm-inline-block"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="13"
            />
            {{ event.ordinalNumber }} / {{ event.calendarEventSequence.calendarEventsCount }}
          </span>

          <span class="text-white">
            {{ event.service.name }}
          </span>

        </h5>

        <b-card-text>
          {{ dayjs(event.startAt).format('HH:mm') }} - {{ dayjs(event.endAt).format('HH:mm') }}

          <div>
            <small
              v-for="(calendarEventCustomer, calendarEventCustomerKey) in event.calendarEventCustomerObjects"
              :key="calendarEventCustomer.id"
            >
              {{ calendarEventCustomer.customer.lastName }}
              {{ calendarEventCustomer.customer.firstName }}<span
                v-if="calendarEventCustomerKey < Object.keys(event.calendarEventCustomers).length - 1"
              >,
              </span>
            </small>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>

    <!-- context -->
    <vue-context
      ref="menuProp"
      lazy
    >
      <li>

        <b-link
          :to="{ name: 'organization-meeting-view', params: { id: event.id } }"
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="SearchIcon"
            size="16"
          />
          <span class="ml-75">{{ $t('shared.btn.open') }}</span>
        </b-link>
      </li>

      <li>
        <b-link
          class="d-flex align-items-center"
          @click="clickEventHandler(event)"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
          />
          <span class="ml-75">{{ $t('dashboard.schedule.item.manage') }}</span>
        </b-link>
      </li>

      <li>
        <b-link
          v-b-modal="`cancel-event-modal-${event.id}`"
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="TrashIcon"
            size="16"
          />
          <span class="ml-75">{{ $t('dashboard.schedule.item.cancel') }}</span>
        </b-link>
      </li>
    </vue-context>

  </div>
</template>
<script>
import {
  BCard, BCardBody, BCardText, BLink, VBModal,
} from 'bootstrap-vue'
import VueContext from 'vue-context'
import Ripple from 'vue-ripple-directive'
import scheduleCalendarEventSetup from '@/views/organization/dashboard/schedule/scheduleCalendarEventSetup'
import CancelEventModal from '@/views/_global/CancelEventModal.vue'

export default {
  components: {
    CancelEventModal,
    BCard,
    BCardBody,
    BLink,
    BCardText,
    VueContext,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup: scheduleCalendarEventSetup,
}
</script>

<style lang="scss" scoped>
.card-muted {
  opacity: .6;
}
</style>
