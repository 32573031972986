import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'
import isEventRecurringValidator from '@/views/_global/calendar-event-sidebar/functions/isEventRecurringValidator'
import CalendarEventTypeDictionary from '@/dictionary/CalendarEventTypeDictionary'

export default () => ({
  processing: computed({
    get: () => store.state.calendarEventEditing.processing,
    set: val => {
      store.commit('calendarEventEditing/SET_PROCESSING', val)
    },
  }),
  formVisible: computed({
    get: () => store.state.calendarEventEditing.formVisible,
    set: val => {
      store.commit('calendarEventEditing/SET_FORM_VISIBLE', val)
    },
  }),
  updateFutureEvents: computed({
    get: () => store.state.calendarEventEditing.updateFutureEvents,
    set: val => {
      store.commit('calendarEventEditing/SET_UPDATE_FUTURE_EVENTS', val)
    },
  }),
  cancelConflictingEvents: computed({
    get: () => store.state.calendarEventEditing.cancelConflictingEvents,
    set: val => {
      store.commit('calendarEventEditing/SET_CANCEL_CONFLICTING_EVENTS', val)
    },
  }),
  overrideServiceDefaults: computed({
    get: () => store.state.calendarEventEditing.overrideServiceDefaults,
    set: val => {
      store.commit('calendarEventEditing/SET_OVERRIDE_SERVICE_DEFAULTS', val)
    },
  }),
  loadingServiceDetails: computed({
    get: () => store.state.calendarEventEditing.loadingServiceDetails,
    set: val => {
      store.commit('calendarEventEditing/SET_LOADING_SERVICE_DETAILS', val)
    },
  }),
  calendarEvent: computed({
    get: () => store.state.calendarEventEditing.event,
    set: val => {
      store.commit('calendarEventEditing/SET_EVENT', val)
    },
  }),
  calendarEventId: computed({
    get: () => store.state.calendarEventEditing.eventId,
    set: val => {
      store.commit('calendarEventEditing/SET_EVENT_ID', val)
    },
  }),
  collisions: computed({
    get: () => store.state.calendarEventEditing.collisions,
    set: val => {
      store.commit('calendarEventEditing/SET_COLLISIONS', val)
    },
  }),
  calendarEventIsRecurring: computed(() => {
    const calendarEvent = store.state.calendarEventEditing.event

    return isEventRecurringValidator(calendarEvent)
  }),
  isEventDifferent: computed(() => {
    const e = store.state.calendarEventEditing.event
    const s = store.state.service.service

    if (!e.service || store.state.calendarEventEditing.loadingServiceDetails) {
      return false
    }

    if (store.state.calendarEventEditing.overrideServiceDefaults) {
      return true
    }

    if (e.additionalTimeBefore !== s.additionalTimeBefore) return true
    if (e.additionalTimeAfter !== s.additionalTimeAfter) return true
    if (e.priceGross !== s.priceGross) return true
    if (e.participantLimit !== s.participantLimit) return true
    if (e.startAt && e.endAt) {
      if (dayjs(e.endAt).diff(e.startAt, 'minute') !== s.duration) return true
    }

    return false
  }),
  isOutOfServiceHours: computed(() => {
    const e = store.state.calendarEventEditing.event
    const s = store.state.service.service

    if (e.type !== CalendarEventTypeDictionary.Appointment) {
      return false
    }

    if (s.serviceTimeFrom) {
      if (s.serviceTimeFrom > dayjs(e.startAt).format('HH:mm')) {
        return true
      }
    }
    if (s.serviceTimeTo) {
      if (s.serviceTimeTo < dayjs(e.endAt).format('HH:mm')) {
        return true
      }
    }
    return false
  }),
})
