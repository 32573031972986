<template>
  <b-modal
    :id="id"
    ref="cancelEventModal"
    v-model="isShowingModal"
    :title="$t(`calendar.event.cancellation-modal.title`)"
    :cancel-title="$t('shared.btn.cancel')"
    cancel-variant="outline-secondary"
    ok-variant="danger"
    :ok-title="$t(`calendar.event.cancellation-modal.confirm-button-text`)"
    :ok-disabled="processing"
    @ok="cancelEvent"
    @show="resetCancellationForm"
    @hidden="resetCancellationForm; $emit('hiding-modal')"
  >
    <form
      id="cancel-event-modal-form"
      autocomplete="off"
      @submit.stop.prevent
    >
      <b-row
        class="mb-50"
      >
        <b-col>
          <dl
            v-if="service"
            class="row mb-0"
          >
            <dt class="col-sm-5">
              {{ $t('calendar.event.cancellation-modal.service-name') }}:
            </dt>
            <dd class="col-sm-7">
              {{ service.name }}
            </dd>
          </dl>
          <dl class="row mb-0">
            <dt class="col-sm-5">
              {{ $t('calendar.event.cancellation-modal.event-time') }}:
            </dt>
            <dd class="col-sm-7">
              {{ transformDatetimeToHumanReadablePretty(calendarEvent.startAt) }}
            </dd>
          </dl>
          <dl
            v-if="!!calendarEvent.calendarEventCustomers.length"
            class="row mb-0"
          >
            <dt class="col-sm-5">
              {{ $tc('shared.plural.attendees-title', calendarEvent.calendarEventCustomers.length) }}:
            </dt>
            <dd class="col-sm-7">
              <p
                v-for="customerToCancel in customersToCancel"
                :key="`customer-to-cancel-${customerToCancel.id}`"
                class="mb-0"
              >
                {{ customerToCancel.lastName }}
                {{ customerToCancel.firstName }}
              </p>
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row
        v-if="!!calendarEvent.calendarEventCustomers.length"
        class="mb-50"
      >
        <b-col>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            :label="$t('calendar.event.cancellation-modal.initiator')"
          >
            <b-form-radio-group
              id="btn-options-initiator"
              v-model="request.initiator"
              :options="initiatorOptions"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              button-variant="outline-primary"
              class="d-flex"
              buttons
            />
          </b-form-group>

        </b-col>
      </b-row>

      <b-row class="mb-50">
        <b-col>
          <b-form-group v-if="calendarEvent.calendarEventSequence && calendarEvent.calendarEventSequence.calendarEventsCount > calendarEvent.ordinalNumber">
            <b-form-checkbox
              v-model="request.cancelFutureEvents"
              name="cancel-future-events-checkbox"
              inline
            >
              {{ $t('calendar.event.cancellation-modal.cancel-future-events') }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="isLateCancellationFinePossible"
            v-acl="[role.admin]"
          >
            <b-form-checkbox
              v-model="request.ignoreLateCancellationFine"
              name="ignore-late-cancellation-fine-checkbox"
              inline
            >
              {{ $t('calendar.event.cancellation-modal.ignore-late-cancellation-fine') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert
            show
            class="p-1"
            variant="danger"
          >
            {{ $t('calendar.event.cancellation-modal.warning-text') }}
          </b-alert>
        </b-col>
      </b-row>

    </form>
  </b-modal>

</template>

<script>
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'
import {
  cloneNested, parseRequestError,
} from '@/helpers/helpers'
import i18n from '@/libs/i18n'
import role from '@/auth/role'
import { transformDatetimeToHumanReadablePretty } from '../../helpers/dateTimeHelper'
import CalendarEventTypeDictionary from '@/dictionary/CalendarEventTypeDictionary'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: 'cancel-event-modal',
    },
    calendarEvent: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: { transformDatetimeToHumanReadablePretty },
  setup(props, { emit }) {
    const requestModel = {
      initiator: 'Teacher',
      cancelFutureEvents: false,
      ignoreLateCancellationFine: false,
    }

    const initiatorOptions = {
      Teacher: i18n.t('calendar.event.cancellation-modal.initiator-teacher'),
      Student: i18n.t('calendar.event.cancellation-modal.initiator-customer'),
    }

    const request = ref(cloneNested(requestModel))
    const isShowingModal = ref(null)
    const processing = ref(null)
    const service = computed(() => props.calendarEvent?.serviceObject ?? props.calendarEvent?.service)
    const customersToCancel = computed(() => {
      if (props.customer) {
        return [props.customer]
      }

      if (!props.calendarEvent?.calendarEventCustomerObjects?.length) {
        return []
      }

      return props.calendarEvent.calendarEventCustomerObjects.map(calendarEventCustomer => calendarEventCustomer.customer)
    })

    const isLateCancellationFinePossible = computed(() => {
      if (request.value.initiator === 'Teacher') {
        return false
      }

      if (service.value?.priceType !== 'PerMeeting') {
        return false
      }

      let calendarEventCustomers = Object.values(props.calendarEvent.calendarEventCustomerObjects)
      if (props.customer) {
        calendarEventCustomers = calendarEventCustomers.filter(calendarEventCustomer => calendarEventCustomer.customer.id === props.customer.id)
      }

      return calendarEventCustomers.some(calendarEventCustomer => {
        if (!calendarEventCustomer.customer.freeCancellationMinHours) {
          return false
        }

        const freeCancellationTime = dayjs().add(calendarEventCustomer.customer.freeCancellationMinHours, 'hour')
        const eventStartAt = dayjs(props.calendarEvent.startAt)

        return freeCancellationTime.isAfter(eventStartAt)
      })
    })

    const resetCancellationForm = () => {
      request.value = cloneNested(requestModel)
    }

    const cancelEvent = () => {
      processing.value = true

      const payload = {
        calendarEventId: props.calendarEvent.id,
      }

      if (props.calendarEvent.type === CalendarEventTypeDictionary.Appointment) {
        payload.initiatedByCustomer = request.value.initiator === 'Student'
        payload.cancelFutureEvents = request.value.cancelFutureEvents
        payload.ignoreLateCancellationFine = service.value.priceType !== 'PerMeeting'
            || request.value.initiator === 'Teacher'
            || request.value.ignoreLateCancellationFine
      }

      if (props.customer) {
        payload.customerId = props.customer.id
      }

      store.dispatch('calendarEventEditing/cancelCalendarEvent', payload)
        .then(() => {
          isShowingModal.value = false
          if (!props.customer) {
            window.toast.notify.success(i18n.t('calendar.event.form.alert.cancel.cancelled'))
            emit('event-cancelled', cloneNested(props.calendarEvent))
          } else {
            window.toast.notify.success(
              i18n.t(
                'calendar.event.form.alert.cancel.cancelled-for-customer',
                { customer: `${props.customer.firstName} ${props.customer.lastName}` },
              ),
            )
            emit('participant-removed', {
              calendarEvent: cloneNested(props.calendarEvent),
              customer: cloneNested(props.customer),
            })
          }
          emit('change')
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    return {
      isShowingModal,
      request,
      processing,
      initiatorOptions,
      role,

      cancelEvent,
      service,
      resetCancellationForm,
      isLateCancellationFinePossible,
      customersToCancel,
    }
  },
}
</script>

<style lang="scss">
#btn-options-initiator {
  > .active {
    background-color: #3a859b !important;
    color: white !important;
  }

  > label {
    flex: 1 1 0;
  }
}
</style>
