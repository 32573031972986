import { computed, ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import dayjs from 'dayjs'
import store from '@/store'
import calendarEventFormValues from '@/views/_global/calendar-event-sidebar/calendarEventFormValues'
import flatPickrSettings from '@/views/_global/calendar-event-sidebar/flatPickrSettings'
import { cloneNested, sort } from '@/helpers/helpers'
import role from '@/auth/role'
import i18n from '@/libs/i18n'
import CalendarEventTypeDictionary from '@/dictionary/CalendarEventTypeDictionary'

export default (props, { emit, root }) => {
  const {
    formVisible,
    processing,
    calendarEventId,
    calendarEvent,
    collisions,
    calendarEventIsRecurring,
    updateFutureEvents,
    overrideServiceDefaults,
    loadingServiceDetails,
    isEventDifferent,
    isOutOfServiceHours,
    cancelConflictingEvents,
  } = calendarEventFormValues()

  const sidebarHeaderTitle = computed(() => {
    let eventTypeHeader = i18n.t('shared.event-type.appointment')
    if (calendarEvent.value.type === CalendarEventTypeDictionary.TimeLock) {
      eventTypeHeader = i18n.t('shared.event-type.time-lock')
    }

    if (calendarEventId.value) {
      return `${i18n.t('calendar.event.form.header.edit')} ${eventTypeHeader.toLowerCase()}`
    }
    return `${i18n.t('calendar.event.form.header.add')} ${eventTypeHeader.toLowerCase()}`
  })
  const services = computed(() => store.getters['service/getServices'])
  const serviceDetails = computed(() => store.getters['service/getService'])
  const customers = computed(() => store.getters['customer/getCustomers']
    .map(customer => ({ customer }))
    .filter(item => !Object.values(calendarEvent.value.calendarEventCustomers).includes(item.customer.id)))
  const eventTypeOptions = computed(() => [
    {
      value: CalendarEventTypeDictionary.Appointment,
      text: i18n.t('shared.event-type.appointment'),
    },
    {
      value: CalendarEventTypeDictionary.TimeLock,
      text: i18n.t('shared.event-type.time-lock'),
    },
  ])

  const customerToRemove = ref(null)

  const me = computed(() => store.getters['auth/getUser'])

  const serviceLocations = computed(() => {
    const locations = [
      {
        id: 'online',
        label: i18n.t('shared.service-location.online'),
      },
      {
        id: 'at_the_teacher',
        label: i18n.t('shared.service-location.at_the_teacher'),
      },
      {
        id: 'at_the_customer',
        label: i18n.t('shared.service-location.at_the_customer'),
      },
    ]

    if (!serviceDetails.value.serviceLocation.length) {
      return locations
    }

    return locations.filter(location => serviceDetails.value.serviceLocation.includes(location.id))
  })

  const selectedCustomer = ref(null)

  const educators = computed(() => store.getters['organization/organizationUsers'])
  if (!educators.value) {
    store.dispatch('organization/fetchOrganizationUsers')
  }

  const {
    dateFromFlatPickrSettings, dateToFlatPickrSettings, sequenceEndDateFlatPickrSettings,
  } = flatPickrSettings()

  const init = () => {
    processing.value = true

    const promises = [
      store.dispatch('service/fetchServices'),
      store.dispatch('customer/fetchCustomers'),
    ]

    if (calendarEventId.value) {
      promises.push(store.dispatch('calendarEventEditing/prepareEventEditing', calendarEventId.value))
    } else {
      calendarEvent.value.calendarObject = me.value.calendar
      calendarEvent.value.calendar = me.value.calendar.id
    }

    Promise.all(promises)
      .then(() => {})
      .finally(() => {
        processing.value = false
      })
  }

  let validatingColisions = false
  const validateCollisions = () => {
    if (validatingColisions) {
      return
    }
    let canDispatch = false
    const requestData = {}
    if (calendarEvent.value.startAt && calendarEvent.value.endAt && calendarEvent.value.calendar) {
      canDispatch = true
      requestData.from = dayjs(calendarEvent.value.startAt)
        .subtract(calendarEvent.value.additionalTimeBefore, 'minutes').format()
      requestData.to = dayjs(calendarEvent.value.endAt)
        .add(calendarEvent.value.additionalTimeAfter, 'minutes').format()
      requestData.calendar = calendarEvent.value.calendar
    }

    if (calendarEvent.value.id) {
      requestData.ignoredEventId = calendarEvent.value.id
    }

    if (calendarEventIsRecurring.value && canDispatch) {
      if (calendarEvent.value.calendarEventSequence?.intervalValue && calendarEvent.value.calendarEventSequence?.intervalType) {
        requestData.intervalType = calendarEvent.value.calendarEventSequence.intervalType
        requestData.intervalValue = calendarEvent.value.calendarEventSequence.intervalValue
        requestData.sequenceEndDate = dayjs(calendarEvent.value.calendarEventSequence.sequenceEndDate).format('YYYY-MM-DD')

        if (calendarEvent.value.calendarEventSequence.id) {
          requestData.ignoredCalendarEventSequenceId = calendarEvent.value.calendarEventSequence.id
        }
      } else {
        canDispatch = false
      }
    }

    if (canDispatch) {
      validatingColisions = true
      store.dispatch(
        'calendarEventEditing/fetchCalendarEventCollisions',
        { params: requestData },
      )
        .finally(() => {
          validatingColisions = false
        })
    }
  }

  const copyServiceSettingsToEvent = () => {
    calendarEvent.value.additionalTimeBefore = serviceDetails.value.additionalTimeBefore
    calendarEvent.value.additionalTimeAfter = serviceDetails.value.additionalTimeAfter
    calendarEvent.value.priceGross = serviceDetails.value.priceGross
    calendarEvent.value.participantLimit = serviceDetails.value.participantLimit
    if (calendarEvent.value.startAt) {
      calendarEvent.value.endAt = dayjs(calendarEvent.value.startAt)
        .add(serviceDetails.value.duration, 'minute')
        .format()
    }
    if (serviceDetails.value.serviceLocation.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      calendarEvent.value.location = serviceDetails.value.serviceLocation[0]
    }
    overrideServiceDefaults.value = false

    validateCollisions()
  }

  const serviceChangeHandler = (newService, oldService) => {
    if (newService && newService !== oldService) {
      loadingServiceDetails.value = true
      store.dispatch('service/fetchService', newService)
        .then(() => {
          if (!calendarEvent.value.id && !oldService) {
            copyServiceSettingsToEvent()
            if (calendarEvent.value.startAt) {
              calendarEvent.value.endAt = dayjs(calendarEvent.value.startAt)
                .add(serviceDetails.value.duration, 'minute')
                .format()
            }
          }

          if (calendarEvent.value.location
            && !serviceLocations.value.map(location => location.id).includes(calendarEvent.value.location)
          ) {
            calendarEvent.value.location = null
          }

          validateCollisions()
        })
        .finally(() => {
          loadingServiceDetails.value = false
        })
    }
  }

  watch(() => calendarEvent.value.service, serviceChangeHandler)

  watch(() => calendarEvent.value.calendar, validateCollisions)

  watch(() => calendarEvent.value.startAt, (newStartAt, oldStartAt) => {
    if (!newStartAt) {
      return
    }

    if (calendarEvent.value.type === CalendarEventTypeDictionary.TimeLock) {
      if (dayjs(newStartAt).format('HH:mm') !== '00:00') {
        calendarEvent.value.fullDay = false
      } else if (dayjs(calendarEvent.value.endAt).format('HH:mm') === '23:59') {
        calendarEvent.value.fullDay = true
      }
    }

    if (calendarEvent.value.type === CalendarEventTypeDictionary.Appointment) {
      const oldEndAt = calendarEvent.value.endAt
      const diff = dayjs(newStartAt)
        .diff(oldStartAt, 'minute')
      if (serviceDetails.value.duration && !oldEndAt) {
        calendarEvent.value.endAt = dayjs(calendarEvent.value.startAt)
          .add(serviceDetails.value.duration, 'minute')
          .format()
      } else if (newStartAt && oldStartAt && oldEndAt) {
        if (diff === 0) {
          calendarEvent.value.endAt = oldEndAt
        } else if (diff > 0) {
          calendarEvent.value.endAt = dayjs(oldEndAt)
            .add(diff, 'minute')
            .format()
        } else if (diff < 0) {
          calendarEvent.value.endAt = dayjs(oldEndAt)
            .subtract(diff * -1, 'minute')
            .format()
        }
      }
    }

    validateCollisions()
  })

  watch(() => calendarEvent.value.endAt, newEndAt => {
    if (!newEndAt) {
      return
    }

    if (calendarEvent.value.type === CalendarEventTypeDictionary.TimeLock) {
      if (dayjs(newEndAt).format('HH:mm') !== '23:59') {
        calendarEvent.value.fullDay = false
      } else if (dayjs(calendarEvent.value.startAt).format('HH:mm') === '00:00') {
        calendarEvent.value.fullDay = true
      }

      validateCollisions()
    }
  })

  const handleNewCustomer = customer => {
    store.dispatch('customer/fetchCustomers')
      .then(() => {
        calendarEvent.value.calendarEventCustomers.push(customer.id)
      })
  }

  const onSubmit = () => {
    if (calendarEvent.value.id) {
      store.dispatch('calendarEventEditing/updateCalendarEvent')
        .then(() => {
          emit('event-updated', cloneNested(calendarEvent.value))
          emit('change')
        })
    } else {
      store.dispatch('calendarEventEditing/addCalendarEvent')
        .then(() => {
          emit('event-added', cloneNested(calendarEvent.value))
          emit('change')
        })
    }
  }

  const searchCustomer = (option, label, searchQuery) => {
    let result = false
    searchQuery.split(' ')
      .forEach(value => {
        if (!value) return false
        result = (option.customer.lastName || '').toLowerCase().includes(value.toLowerCase())
          || (option.customer.firstName || '').toLowerCase().includes(value.toLowerCase())
          || (option.customer.phone || '').toLowerCase().includes(value.toLowerCase())
          || (option.customer.email || '').toLowerCase().includes(value.toLowerCase())

        return result
      })
    return result
  }

  const resetCalendarEvent = () => {
    store.commit('calendarEventEditing/CLEAR_EVENT')
  }

  const addParticipant = participant => {
    if (typeof calendarEvent.value.calendarEventCustomers === 'object') {
      calendarEvent.value.calendarEventCustomers = Object.values(calendarEvent.value.calendarEventCustomers)
    }
    calendarEvent.value.calendarEventCustomers.push(participant.customer.id)

    if (typeof calendarEvent.value.calendarEventCustomerObjects === 'object') {
      calendarEvent.value.calendarEventCustomerObjects = Object.values(calendarEvent.value.calendarEventCustomerObjects)
    }
    calendarEvent.value.calendarEventCustomerObjects.push(participant)
    selectedCustomer.value = null
  }

  const removeParticipantEventHandler = customer => {
    calendarEvent.value.calendarEventCustomerObjects.forEach((calendarEventCustomerObject, index) => {
      if (calendarEventCustomerObject.customer.id === customer.id) {
        if (calendarEventCustomerObject.id) {
          customerToRemove.value = customer
          root.$emit('bv::show::modal', 'cancel-event-modal')
        } else {
          calendarEvent.value.calendarEventCustomers.splice(index, 1)
          calendarEvent.value.calendarEventCustomerObjects.splice(index, 1)
        }
      }
    })
  }

  const participantRemovedEventHandler = data => {
    const index = calendarEvent.value.calendarEventCustomers.indexOf(data.customer.id)
    if (index > -1) {
      calendarEvent.value.calendarEventCustomers.splice(index, 1)
    }

    let calendarEventCustomerObjectIndex = null
    calendarEvent.value.calendarEventCustomerObjects.forEach((calendarEventCustomerObject, i) => {
      if (calendarEventCustomerObject.customer.id === data.customer.id) {
        calendarEventCustomerObjectIndex = i
      }
    })

    if (calendarEventCustomerObjectIndex !== null) {
      calendarEvent.value.calendarEventCustomerObjects.splice(calendarEventCustomerObjectIndex, 1)
    }
  }

  const {
    refFormObserver,
    getValidationState,
    resetForm,
  } = formValidation(resetCalendarEvent, props.clearEventData)

  const fullDayChangeHandler = value => {
    if (value) {
      calendarEvent.value.startAt = dayjs(calendarEvent.value.startAt)
        .startOf('day')
        .format()
      calendarEvent.value.endAt = dayjs(calendarEvent.value.endAt)
        .endOf('day')
        .format()
    }
  }

  const eventTypeChangeHandler = value => {
    if (value === CalendarEventTypeDictionary.TimeLock) {
      if (!calendarEvent.value.startAt) {
        calendarEvent.value.startAt = dayjs().format()
      }

      if (!calendarEvent.value.endAt) {
        calendarEvent.value.endAt = dayjs(calendarEvent.value.startAt).add(1, 'hour').format()
      }
      calendarEvent.value.fullDay = true
      fullDayChangeHandler(true)
    }
  }

  return {
    init,
    serviceChangeHandler,
    validateCollisions,
    eventTypeChangeHandler,
    fullDayChangeHandler,

    processing,
    formVisible,
    calendarEvent,
    collisions,
    selectedCustomer,
    sidebarHeaderTitle,
    cancelConflictingEvents,

    // old
    dateFromFlatPickrSettings,
    dateToFlatPickrSettings,
    sequenceEndDateFlatPickrSettings,
    dayjs,

    me,
    role,
    services,
    customers,
    educators,
    serviceLocations,
    customerToRemove,
    eventTypeOptions,

    // new customer
    handleNewCustomer,

    // Add New Event
    serviceDetails,
    updateFutureEvents,
    onSubmit,
    copyServiceSettingsToEvent,
    searchCustomer,
    addParticipant,
    removeParticipantEventHandler,

    // cancel event / remove participant
    participantRemovedEventHandler,

    // Form Validation
    overrideServiceDefaults,
    isEventDifferent,
    calendarEventIsRecurring,
    isOutOfServiceHours,
    resetForm,
    refFormObserver,
    getValidationState,
    sort,

    CalendarEventTypeDictionary,
  }
}
