<template>
  <div>
    <calendar-event-sidebar @change="refetchEvents" />

    <b-card
      class="schedule-card"
      no-body
    >

      <div class="d-flex justify-content-between">
        <h4 class="schedule-card-title">
          {{ $t('dashboard.schedule.title') }}
          <small class="d-block text-muted">
            {{ formatMonth(selectedMonth) }}
          </small>
        </h4>

        <div class="p-1">

          <b-button
            v-if="nowMarker.length"
            size="sm"
            variant="flat-primary"
            class="app-tour-dashboard-add-meeting"
            @click="addEventClickHandler"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>

          <b-button
            v-if="nowMarker.length"
            size="sm"
            variant="flat-primary"
            class="app-tour-dashboard-goto-now"
            @click="scrollToNow()"
          >
            <feather-icon icon="CrosshairIcon" />
          </b-button>

          <b-button
            size="sm"
            variant="flat-primary"
            class="app-tour-dashboard-change-month"
            @click="calendarButtonClickHandler"
          >
            <feather-icon icon="CalendarIcon" />
          </b-button>

          <div class="position-relative">
            <flat-pickr
              ref="monthSelector"
              v-model="selectedMonth"
              class="hidden-flatpickr d-flex"
              :config="configDateTimePicker"
              :name="$t('report.reports.filters.month')"
              @on-change="monthChangeEventHandler"
            />
          </div>
        </div>
      </div>

      <b-card-body class="pt-1">
        <b-alert :show="Object.values(events).length === 0 && !processing">
          <h4 class="alert-heading">
            {{ $t('dashboard.schedule.alert.no-meetings-title') }}
          </h4>
          <div class="alert-body p-1">

            <b-link
              class="alert-link"
              @click="addEventClickHandler"
            >
              {{ $t('dashboard.schedule.alert.no-meetings-body-add-meeting') }},
            </b-link>
            {{ $t('shared.or') }}
            <b-link
              class="alert-link"
              @click="calendarButtonClickHandler"
            >
              {{ $t('dashboard.schedule.alert.no-meetings-body-change-period') }}</b-link>.
          </div>
        </b-alert>

        <rc-overlay
          :show="processing"
          no-fade
          variant="white"
          class="h-100 d-flex"
        >

          <vue-perfect-scrollbar
            v-show="Object.values(events).length"
            ref="scrollContainer"
            :settings="perfectScrollbarSettings"
            class="schedule-scroll-area"
          >
            <div
              v-for="(events, day) in eventsByDay"
              :key="day"
              class="mr-1 mb-2"
            >
              <rc-divider class="divider-left font-weight-bold">
                {{ formatDay(day) }}
              </rc-divider>
              <div
                v-for="event in events"
                :key="event.id"
              >

                <rc-divider
                  v-if="event.nowMarker"
                  ref="nowMarker"
                  class="divider-left strong font-weight-bold"
                >
                  <feather-icon icon="PlayIcon" />
                </rc-divider>

                <schedule-calendar-event
                  v-else-if="event.type === CalendarEventTypeDictionary.Appointment"
                  :event="event"
                  @refetch="refetchEvents"
                />

              </div>
            </div>
          </vue-perfect-scrollbar>
        </rc-overlay>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAlert, BButton, BCard, BCardBody, BLink,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import scheduleSetup from '@/views/organization/dashboard/schedule/scheduleSetup'
import ScheduleCalendarEvent from '@/views/organization/dashboard/schedule/ScheduleCalendarEvent'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import RcDivider from '@/layouts/components/rc/RcDivider'
import CalendarEventSidebar from '@/views/_global/calendar-event-sidebar/CalendarEventSidebar'

export default {
  components: {
    RcDivider,
    ScheduleCalendarEvent,
    CalendarEventSidebar,
    BAlert,
    BButton,
    BCard,
    BCardBody,
    BLink,
    VuePerfectScrollbar,
    RcOverlay,
    flatPickr,
  },
  setup: scheduleSetup,
}
</script>
