import dayjs from 'dayjs'
import { computed, ref } from '@vue/composition-api'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import store from '@/store'
import { capitalizeFirstLetter, parseRequestError, safePush } from '@/helpers/helpers'
import i18n from '@/libs/i18n'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import CalendarEventTypeDictionary from '@/dictionary/CalendarEventTypeDictionary'

export default () => {
  const selectedMonth = ref(dayjs().format('YYYY-MM'))
  const monthSelector = ref(null)
  const processing = ref(false)

  const nowMarker = ref()
  const scrollContainer = ref()
  const events = computed(() => store.getters['calendar/getCalendarEvents'])

  const calendarEventToCancel = ref(null)
  const eventsByDay = computed(() => {
    const localEvents = {}
    let isNowSet = false
    const todayDayString = dayjs().format('YYYY-MM-DD')

    store.getters['calendar/getCalendarEvents'].forEach(calendarEvent => {
      const dayString = dayjs(calendarEvent.startAt)
        .format('YYYY-MM-DD')
      const endAtTime = dayjs(calendarEvent.endAt)

      if (isNowSet === false && dayjs().diff(endAtTime) < 0) {
        if (dayjs(endAtTime).format('YYYYMMDD') === dayjs().format('YYYYMMDD')) {
          localEvents[dayString] = safePush(localEvents[dayString], { nowMarker: true })
        } else if (selectedMonth.value === dayjs().format('YYYY-MM')) {
          localEvents[todayDayString] = safePush(localEvents[todayDayString], { nowMarker: true })
        }
        isNowSet = true
      }
      localEvents[dayString] = safePush(localEvents[dayString], calendarEvent)
    })

    if (!isNowSet && selectedMonth.value === dayjs().format('YYYY-MM')) {
      localEvents[todayDayString] = safePush(localEvents[todayDayString], { nowMarker: true })
    }

    return localEvents
  })

  const perfectScrollbarSettings = {
    maxScrollbarLength: 150,
  }

  const configDateTimePicker = {
    locale: PolishLocale,
    disableMobile: true,
    plugins: [
      // eslint-disable-next-line new-cap
      new monthSelectPlugin({
        dateFormat: 'Y-m', // defaults to "F Y"
        altFormat: 'Y-m', // defaults to "F Y"
      }),
    ],
  }

  const scrollToNow = () => {
    scrollContainer.value.$el.scrollTop = 0
    if (nowMarker.value.length > 0) {
      scrollContainer.value.$el.scrollTop = nowMarker.value[0].$el.offsetTop
    }
  }

  const fetchEvents = month => {
    processing.value = true
    nowMarker.value = []

    const from = dayjs(month).startOf('month')
    const to = dayjs(month).endOf('month')

    store
      .dispatch('calendar/fetchCalendarEvents', {
        from: from.format(),
        to: to.format(),
        types: [CalendarEventTypeDictionary.Appointment],
      })
      .then(() => {
        processing.value = false
        scrollToNow()
      })
      .catch(err => parseRequestError(err))
  }
  const refetchEvents = () => {
    fetchEvents(`${selectedMonth.value}-01`)
  }
  refetchEvents()

  const formatDay = day => {
    const dayObject = dayjs(day)
    const dayString = capitalizeFirstLetter(i18n.t(`shared.days.long.${dayObject.day()}`))
    const monthString = i18n.t(`shared.months-for-full-date.${dayObject.month()}`)
    return `${dayString}, ${dayObject.date()} ${monthString} ${dayObject.year()}r`
  }

  const formatMonth = day => {
    const dayObject = dayjs(day)
    const monthString = i18n.t(`shared.months.${dayObject.month()}`)
    return `${monthString} ${dayObject.year()}r`
  }

  const addEventClickHandler = () => {
    store.dispatch('calendarEventEditing/activateEditing')
  }

  const monthChangeEventHandler = month => {
    // eslint-disable-next-line
    fetchEvents(dayjs(month).format('YYYY-MM-DD'))
  }

  const calendarButtonClickHandler = () => {
    // eslint-disable-next-line
    monthSelector.value.$el._flatpickr.open()
  }

  const handleEventCancelled = () => {
    calendarEventToCancel.value = null
    refetchEvents()
  }

  return {
    processing,
    nowMarker,
    scrollContainer,
    selectedMonth,
    monthSelector,

    // cancel calendar event,
    calendarEventToCancel,
    handleEventCancelled,

    events,
    eventsByDay,
    perfectScrollbarSettings,
    configDateTimePicker,
    addEventClickHandler,
    calendarButtonClickHandler,
    monthChangeEventHandler,
    fetchEvents,
    refetchEvents,

    dayjs,
    formatDay,
    formatMonth,
    scrollToNow,

    CalendarEventTypeDictionary,
  }
}
