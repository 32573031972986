<template>
  <div class="dashboard">

    <b-row
      v-if="me.organization.subscriptionType === 'Trial'"
      class="mt-1"
    >
      <b-col
        cols="12"
        xl="10"
      >
        <b-alert
          show
          variant="primary"
          class="p-1 text-center shadow"
        >
          <p>
            {{ $t('dashboard.schedule.trial-info.info') }}
            <b>{{ $tc('dashboard.schedule.trial-info.ends-count-days', dayjs(me.organization.subscriptionEndsAt).diff(dayjs(), 'days'), {n: dayjs(me.organization.subscriptionEndsAt).diff(dayjs(), 'days')}) }}</b>
            {{ $t('dashboard.schedule.trial-info.ends-at', { day: transformDateToHumanReadablePretty(me.organization.subscriptionEndsAt), time: transformTimeToHumanReadablePretty(me.organization.subscriptionEndsAt) }) }}
          </p>
          <p>
            <b-link :to="{name: 'checkout'}">
              <b>
                {{ $t('dashboard.schedule.trial-info.click-here') }}
              </b>
            </b-link>
            {{ $t('dashboard.schedule.trial-info.contact-to') }}
            <a
              class="text-primary"
              href="mailto:hi@totedi.com?subject=Przedłużenie subskrypcji!"
              target="_blank"
            >
              <b>hi@totedi.com</b>
            </a>.
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row
      v-if="['Yearly', 'Monthly'].includes(me.organization.subscriptionType) && dayjs(me.organization.subscriptionEndsAt).diff(dayjs(), 'days') < 3"
      class="mt-1"
    >
      <b-col
        cols="12"
        xl="10"
      >
        <b-alert
          show
          variant="primary"
          class="p-1 text-center shadow"
        >
          <p>
            {{ $t(`dashboard.schedule.subscription-ends-info.info-${me.organization.subscriptionType}`) }}
            {{ $tc('dashboard.schedule.subscription-ends-info.ends-count-days', dayjs(me.organization.subscriptionEndsAt).diff(dayjs(), 'days'), {n: dayjs(me.organization.subscriptionEndsAt).diff(dayjs(), 'days')}) }}
            {{ $t('dashboard.schedule.subscription-ends-info.extended') }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row class="mt-1 match-height">

      <b-col
        cols="12"
        md="6"
        xl="5"
        class="app-tour-dashboard-column-left"
      >
        <dashboard-statistics />
      </b-col>

      <b-col
        cols="12"
        md="6"
        xl="5"
        class="app-tour-dashboard-column-right"
      >
        <schedule />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import dashboardSetup from '@/views/organization/dashboard/dashboardSetup'
import Schedule from '@/views/organization/dashboard/schedule/Schedule'
import DashboardStatistics from '@/views/organization/dashboard/statistics/DashboardStatistics'

export default {
  components: {
    DashboardStatistics,
    Schedule,
  },
  setup: dashboardSetup,
}
</script>
