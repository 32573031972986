<template>
  <b-card class="text-center">
    <rc-overlay
      :show="loading"
      no-fade
      variant="white"
    >
      <b-avatar
        class="mb-1"
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
      <div class="truncate">
        <h2
          v-if="hide"
          class="mb-25 text-muted"
        >
          {{ $t('dashboard.statistics.hidden') }}
        </h2>
        <h2
          v-else
          class="mb-25 font-weight-bolder"
        >
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
    </rc-overlay>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

export default {
  components: {
    BCard,
    BAvatar,
    RcOverlay,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
