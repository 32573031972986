import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'
import { transformDateToHumanReadablePretty, transformTimeToHumanReadablePretty } from '@/helpers/dateTimeHelper'

export default () => {
  const me = computed(() => store.getters['auth/getUser'])
  return {
    me,
    dayjs,
    transformDateToHumanReadablePretty,
    transformTimeToHumanReadablePretty,
  }
}
